import { Category } from '@wix/answers-api';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { Box, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';

import { TopicsSectionCard } from './TopicsSectionCard';
import { BI, TOPICS_SECTION_NUM_ITEMS, WIXEL_CATEGORY_ID } from '../../constants';
import { DATA_HOOKS } from '../../dataHooks';
import { useBI } from '../../hooks/useBI';

import css from './index.module.scss';

export type TopicsSectionProps = {
  topics: Category[];
};

export const TopicsSection: FunctionComponent<TopicsSectionProps> = ({
  topics,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();

  const limitedTopics = topics.filter(
    (topic) => topic.id !== WIXEL_CATEGORY_ID
  ).slice(0, TOPICS_SECTION_NUM_ITEMS);

  const { SEE_ALL, CATEGORY_SELECTION, SUB_CATEGORY_SELECTION } =
    BI.CLICKED_ITEM_TYPES;

  const onClickTopicEvent = async (
    topic: Category,
    itemOrder: number,
    categoryOrder: number,
    itemType: string
  ) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale as string,
        clicked_item_type: itemType,
        clicked_url: topic.url,
        clicked_text: topic.name,
        clicked_item_order: `${itemOrder}`,
        clicked_item_id: topic.id,
        category_order: categoryOrder,
      })
    );
  };

  const onClickShowAllEvent = async (
    topic: Category,
    categoryOrder: number,
    fromTitle: boolean
  ) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.HOME,
        kb_lang: locale as string,
        clicked_item_type: fromTitle ? CATEGORY_SELECTION : SEE_ALL,
        clicked_url: topic.url,
        clicked_text: fromTitle
          ? topic.name
          : t('homepage.topics-section.show-all'),
        clicked_item_id: topic.id,
        category_order: categoryOrder,
      })
    );
  };

  return (
    <Box
      direction="vertical"
      alignContent="start"
      className={css.TopicsSectionWrapper}
      dataHook={DATA_HOOKS.TOPICS_SECTION}
    >
      <Heading
        dataHook={DATA_HOOKS.TOPICS_SECTION_TITLE}
        className={css.TopicsSectionTitle}
        as="h2"
      >
        {t('homepage.topics-section.title')}
      </Heading>
      <Text
        heading="H3"
        size="medium"
        weight="normal"
        dataHook={DATA_HOOKS.TOPICS_SECTION_DESCRIPTION}
        className={css.TopicsSectionDescription}
      >
        {t('homepage.topics-section.description')}
      </Text>
      <Box
        direction="horizontal"
        alignContent="center"
        align="left"
        flexWrap="wrap"
        className={css.TopicsCardsWrapper}
      >
        {limitedTopics.map((topic, idx) => (
          <TopicsSectionCard
            key={topic.id}
            childTopics={topic.children}
            title={topic.name}
            url={topic.uri}
            imageSrc={topic.data.iconSrc}
            withOnViewEvent={idx === 0}
            onClickShowAll={(isTitleClicked) =>
              onClickShowAllEvent(topic, idx + 1, isTitleClicked)
            }
            onClickChildTopic={(childTopic, itemOrder) =>
              onClickTopicEvent(
                childTopic,
                idx + 1,
                itemOrder,
                SUB_CATEGORY_SELECTION
              )
            }
          />
        ))}
      </Box>
    </Box>
  );
};
