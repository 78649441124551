import { ArticleType } from '@wix/answers-api';
import {
  clickTheSeeAllResultsLink,
  askingAQuestion,
  clickOnAnswer,
} from '@wix/bi-logger-customer-care-data/v2';
import { ArticleSearchResultDocumentType } from '@wix/cc-user-facing-common-components';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { useRouter } from 'next/router';

import { BI, EXPERIMENTS, GEN_ANSWER_RELATED_ARTICLES_COUNT } from '../../constants';
import { useBI } from '../../hooks/useBI';
import { useIsSitePickerEnabled } from '../../hooks/useIsSitePickerEnabled';
import {
  ArticleSearchResultItem,
  ArticleSourceType,
  PageType,
  SiteMetaData,
} from '../../types';
import {
  mapArticleTypeToItemType,
  mapResourceTypeToItemType,
  mapPageTypeToSourceName,
  cleanGeneratedAnswer,
} from '../../utils/bi';
import { countWords } from '../../utils/text';
import { useStore } from '../../zustand/stores';

export enum SummarizedAnswerType {
  SUPPORT_REQUEST = 'support request',
  PROHIBITED = 'prohibited',
  GENERATE_ANSWER_FAILED = 'answer generation failed',
  REGULAR = 'regular',
}

export type ClickOnResultBI = {
  url: string;
  index: number;
  resultSourceType?: ArticleType;
  docType: ArticleSearchResultDocumentType;
  inputText: string;
  pageType: PageType;
  clickedItemId?: string;
  itemType?: string;
  clickedItemType?: string;
  id?: string;
  genAnswerBasedOnArticles?: string;
  genAnswerWordsCount?: number;
  genAnswer?: string;
  searchMethod?: string;
  selfServiceSessionId?: string;
  searchResultSelection?: string;
  summarizedAnswerType?: string;
};

export type SearchQuestionBIParams = {
  results: ArticleSearchResultItem[] | ArticleSearchResultItem[],
  pageType: PageType,
  inputText: string,
  searchMethod?: string,
  searchVersion?: string,
  inputType?: string,
  genAnswerBasedOnArticles?: string,
  genAnswerWordsCount?: number,
  isContactSupport?: boolean;
  isProhibited?: boolean;
  isGenerateAnswerFailed?: boolean;
  searchSessionId?: string;
};

export const useSearchBarBI = () => {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const { experiments } = useExperiments({ readOnly: true });
  const { searchSessionId } = useStore((state) => state.biSlice);

  const isSitePickerEnabled = useIsSitePickerEnabled();
  const isContactClassifierEnabled = experiments.enabled(EXPERIMENTS.SPECS.IS_CONTACT_CLASSIFIER_ENABLED);

  const clickAllResultsBI = async (
    inputText: string,
    pageType: PageType,
    clear: () => void,
    itemId?: string,
    itemType?: string,
    resourceType?: ArticleSourceType | string
  ) => {
    const typeTrigger =
      resourceType === 'general'
        ? 'general'
        : mapResourceTypeToItemType(resourceType as ArticleSourceType);
    await sendBIEvent(
      clickTheSeeAllResultsLink({
        source_name: mapPageTypeToSourceName(pageType),
        item_id: itemId,
        item_type: itemType,
        kb_lang: locale as string,
        question: inputText,
        search_session_Id: searchSessionId,
        source_type_trigger: typeTrigger,
      })
    );
    clear();
  };

  const clickOnResultBI = async (params: ClickOnResultBI) => {
    const itemTypeMigration = params.clickedItemType ? { clicked_item_type: params.clickedItemType } : {};
    const itemType =
      params.docType === ArticleSearchResultDocumentType.WIX_DOCS_ARTICLE ?
        BI.CLICKED_ITEM_TYPES.WIX_DOCS : mapArticleTypeToItemType(params.resultSourceType as ArticleType);

    await sendBIEvent(
      // evid 521
      clickOnAnswer({
        question: params.inputText,
        source_name: mapPageTypeToSourceName(params.pageType),
        item_id: params.clickedItemId,
        item_type: itemType,
        kb_lang: locale as string,
        clicked_url: params.url,
        clicked_item_id: params.clickedItemId,
        clicked_item_order: `${params.index + 1}`,
        search_session_Id: searchSessionId,
        clicked_item_type: itemType,
        search_result_selection: params.searchResultSelection || BI.SEARCH_RESULT_SELECTION.SEARCH_BAR_DROPDOWN_LIST,
        self_service_session_id: params.selfServiceSessionId,
        search_method: params.searchMethod,
        summarized_answer_based_on: params.genAnswerBasedOnArticles,
        summarized_answer_words_count: params.genAnswerWordsCount,
        summarized_answer_text: params.genAnswer,
        summarized_answer_type: params.summarizedAnswerType || undefined,
        ...itemTypeMigration
      })
    );
  };

  const searchQuestionBI =
    async (
      params: SearchQuestionBIParams,
      generatedAnswer?: string | null,
      relatedArticlesTitles?: string[],
      siteSelector?: SiteMetaData
    ) => {
      if (!searchSessionId) {
        return;
      }
      const isShowingContactSupport = params.isContactSupport && isContactClassifierEnabled;
      const shouldCalculateGenerateAnswerParams = generatedAnswer && generatedAnswer?.length;

      const getParsedArticles = () => {
        const searchResults = params.results;

        if (!shouldCalculateGenerateAnswerParams || !searchResults) {
          return undefined;
        }

        const topArticles = searchResults.slice(0, GEN_ANSWER_RELATED_ARTICLES_COUNT);
        const relatedArticles = topArticles
          .filter((article) => relatedArticlesTitles?.includes(article.title));
        const parsedRelatedArticles = relatedArticles.map((article) => ({
          article_id: article.id,
          output_distance: article.distance,
        }));

        return JSON.stringify(parsedRelatedArticles);
      };

      const genAnswerWordCount = shouldCalculateGenerateAnswerParams ? countWords(generatedAnswer) : undefined;

      const getSiteSelectorProps = () => {
        if (!isSitePickerEnabled) {
          return {};
        }
        if (siteSelector) {
          return {
            context_msid: siteSelector.metaSiteId,
            context_editor_type: siteSelector.editor.editorType,
          };
        }
        return {
          context_msid: BI.CLICKED_ITEM_TYPES.ALL_SITES,
          context_editor_type: BI.CONTEXT_EDITOR_TYPE.ALL,
        };
      };

      const getSummarizedAnswerType = () => {
        if (isShowingContactSupport) {
          return SummarizedAnswerType.SUPPORT_REQUEST;
        }
        if (params.isProhibited) {
          return SummarizedAnswerType.PROHIBITED;
        }
        if (params.isGenerateAnswerFailed) {
          return SummarizedAnswerType.GENERATE_ANSWER_FAILED;
        }
        if (generatedAnswer) {
          return SummarizedAnswerType.REGULAR;
        }
        return undefined;
      };

      await sendBIEvent(
        // evid 520
        askingAQuestion({
          source_name: mapPageTypeToSourceName(params.pageType),
          kb_lang: locale as string,
          presented_content: JSON.stringify(
            (params.results as ArticleSearchResultItem[]).map((article, index) => ({
              order: index + 1,
              item_id: article.id,
              locale,
              type: mapArticleTypeToItemType(article.type, article.docType, article.academyType),
              output_distance: article.distance ? article.distance : null,
            }))
          ),
          question: params.inputText,
          search_session_Id: params.searchSessionId,
          search_method: params.searchMethod,
          search_version: params.searchVersion,
          input_type: params.inputType,
          summarized_answer_based_on: !isShowingContactSupport ? getParsedArticles() : undefined,
          summarized_answer_type: getSummarizedAnswerType(),
          summarized_answer_words_count: !isShowingContactSupport ? genAnswerWordCount : undefined,
          summarized_answer_text:
            (generatedAnswer && !isShowingContactSupport) ? cleanGeneratedAnswer(generatedAnswer) : undefined,
          is_support_request: params.isContactSupport,
          ...getSiteSelectorProps()
        })
      );
    };

  return {
    searchQuestionBI,
    clickAllResultsBI,
    clickOnResultBI,
  };
};

