import { Box, Divider, Heading } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent, useContext } from 'react';
import { PageType } from '../../types';
import { DATA_HOOKS } from '../../dataHooks';
import { SearchBar } from '../SearchBar/Index';
import css from './index.module.scss';
import { RenderHTML } from '@wix/cc-user-facing-common-components';
import { handleLongFirstName } from '../../utils/text';
import { Context } from '../../context';


export const Hero: FunctionComponent = () => {
  const { t } = useTranslation();
  const { user } = useContext(Context);
  const userFirstName = user?.firstName;

  return (
    <Box
      className={css.wrapper}
      direction="vertical"
      verticalAlign="middle"
      align="center"
    >
      <Heading
        size="extraLarge"
        dataHook={DATA_HOOKS.HOMEPAGE_TITLE}
        className={css.personalizedTitle}
      >
        {userFirstName ? (
          <RenderHTML
            html={t('homepage.personalized-title', {
              firstName: handleLongFirstName(userFirstName),
            })}
          />
        ) : (
          t('homepage.title')
        )}
      </Heading>
      <Box className={css.searchBarWrapper}>
        <SearchBar pageType={PageType.Homepage} homepageNewDesign />
      </Box>
      <Divider direction="horizontal" />
    </Box>
  );
};
